import type { IStripeAccount } from '@medely/types';
import { Box, Button, Card, HStack, Text, VStack, InfoBadge, Icon } from '@medely/ui-kit';
import { useProNavigate } from '@src/contexts';
import useTaxClassifications from '@src/hooks/useTaxClassifications';
import React from 'react';

type Props = {
  account: IStripeAccount | null;
  currentUserName: string;
};

export const AccountCard = ({ account, currentUserName }: Props) => {
  const { navigate } = useProNavigate();
  const { taxClassifications } = useTaxClassifications();
  const selectedTaxClassificationLabel =
    account?.tax_classification_id === 1
      ? 'Personal account'
      : taxClassifications.find((item) => item.id === account?.tax_classification_id)?.label;

  const externalAccount = account?.external_accounts?.[0];
  const haveExternalAccount = Boolean(externalAccount);
  const iconName = externalAccount?.account_type === 'card' ? 'credit-card' : 'landmark';

  function handleClickCard() {
    if (!account) {
      return;
    }

    navigate('AccountDetails', { id: account?.id });
  }

  function getName() {
    if (!haveExternalAccount && account?.company_name) {
      return account?.company_name;
    }

    if (account?.first_name) {
      return `${account.first_name} ${account.last_name}`;
    }

    return currentUserName;
  }

  function handleClickSetUpDirectDeposit() {
    navigate('PayoutMethod', {});
  }

  return (
    <HStack>
      <Card mt={2} maxHeight={!haveExternalAccount ? 196 : 105}>
        <Box onClick={handleClickCard}>
          <HStack alignItems="center">
            <Text size="m" bold>
              {getName()}
            </Text>
            {haveExternalAccount && (
              <Box ml={1}>
                <InfoBadge color="success" label="Active" />
              </Box>
            )}
          </HStack>
          <Text size="s">{selectedTaxClassificationLabel}</Text>
          {haveExternalAccount && (
            <HStack alignItems="center" mt={1}>
              <Icon name={iconName} />
              <Box ml={1}>
                <Text size="s" bold>{`•••• ${externalAccount?.last4}`}</Text>
              </Box>
              {externalAccount?.account_type === 'card' && (
                <Box ml={1}>
                  <Text size="s">
                    exp {externalAccount.exp_month}/{externalAccount.exp_year}
                  </Text>
                </Box>
              )}
            </HStack>
          )}
        </Box>

        {!haveExternalAccount && (
          <VStack mt={1} pt={2} borderTopWidth={1} borderColor="controls.backing+line">
            <Box mb={2}>
              <Text size="s">Set up direct deposit to start receiving payouts.</Text>
            </Box>

            <Button variant="contained" color="primary" onClick={handleClickSetUpDirectDeposit}>
              Set up direct deposit
            </Button>
          </VStack>
        )}
      </Card>
    </HStack>
  );
};
