import React from 'react';
import { TaxInfoForm } from './TaxInfoForm';
import { FormProvider, useForm } from 'react-hook-form';
import { TaxInfoTabSchema } from '@src/models/settings/tax-info/TaxInfo.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Heading } from '@medely/ui-kit';
import type { NotificationT } from './types';
import { INDIVIDUAL_TAX_CLASSIFICATION_ID } from '@src/constants/tax';
import { format, add } from 'date-fns';
import { useCurrentUser } from '@src/hooks';
import { toPhoneNumber } from '@src/utils/toPhoneNumber';

type TaxInfoTabProps = {
  account: any;
  notification: NotificationT;
};

export const TaxInfoTab = ({ account, notification }: TaxInfoTabProps) => {
  const isIndividual = account?.tax_classification_id === INDIVIDUAL_TAX_CLASSIFICATION_ID;
  const isEditing = !!account?.id;

  const { currentUser } = useCurrentUser();

  function isRepresentativeAddressSameAsAccountAddress() {
    const { address_city, address_first, address_zip } = account;
    const {
      address_city: rep_address_city,
      address_first: rep_address_first,
      address_zip: rep_address_zip,
    } = account.representative;

    return (
      address_city === rep_address_city &&
      address_first === rep_address_first &&
      address_zip === rep_address_zip
    );
  }

  const methods = useForm({
    defaultValues: {
      address_city: account?.address_city || '',
      address_first: account?.address_first || '',
      address_second: account?.address_second || '',
      address_zip: String(account?.address_zip || ''),
      company_name: account?.company_name || '',
      personal_id_number: String(account?.personal_id_number || ''),
      state_id: String(account?.state_id || ''),
      tax_classification_id: String(account?.tax_classification_id || ''),
      representative: account?.representative
        ? {
            ...account.representative,
            ssn_last_4: String(account?.representative.ssn_last_4 || 0),
            phone: toPhoneNumber(account?.representative.phone || ''),
            dob: account?.representative?.dob
              ? format(add(new Date(account?.representative?.dob), { days: 1 }), 'yyyy-MM-dd')
              : null,
            use_business_address: isRepresentativeAddressSameAsAccountAddress(),
          }
        : {
            use_business_address: true,
            state_id: currentUser?.state_id,
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            email: currentUser?.email,
            phone: toPhoneNumber(currentUser?.phone_number || ''),
            title: 'Owner',
          },
    },
    resolver: yupResolver(TaxInfoTabSchema(isIndividual)),
  });

  return (
    <Box width="90%" my={3} ml={1}>
      <Box mb={2}>
        <Heading size="m" data-testid="tax-info-title">
          {isEditing ? 'Edit' : 'Add'} {isIndividual ? 'Personal' : 'Business'} account
        </Heading>
      </Box>
      <FormProvider {...methods}>
        <TaxInfoForm account={account} notification={notification} isIndividual={isIndividual} />
      </FormProvider>
    </Box>
  );
};
