import { yup } from '@medely/ui-kit';
import { differenceInYears } from 'date-fns';

const useBusinessAddressIsNotChecked = (
  use_business_address: boolean,
  schema: yup.StringSchema,
  label: string,
) => {
  return use_business_address
    ? schema.notRequired().nullable()
    : schema.required(`${label} is a required field`);
};

export const TaxInfoTabSchema = (isIndividual: boolean) => {
  return yup.object().shape({
    first_name: isIndividual
      ? yup.string().required('First name is a required field')
      : yup.mixed().nullable(),
    last_name: isIndividual
      ? yup.string().required('Last name is a required field')
      : yup.mixed().nullable(),

    tax_classification_id: yup.string().required('Tax classification is a required field'),
    company_name: isIndividual
      ? yup.mixed()
      : yup.string().required('Company name is a required field'),
    personal_id_number: yup
      .string()
      .required(`${isIndividual ? 'SSN' : 'EIN'} number is a required field`)
      .matches(/^[0-9]*$/, `${isIndividual ? 'SSN' : 'EIN'} should only contain numbers`)
      .length(9, `${isIndividual ? 'SSN' : 'EIN'} number should have 9 characters`),
    address_first: yup.string().required('Street Address is a required field'),
    address_second: yup.string().optional().nullable(),
    address_city: yup.string().required('City is a required field'),
    state_id: yup.string().required('State is a required field'),
    address_zip: yup
      .string()
      .required('Zipcode is a required field')
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Zip code is not valid one'),
    representative: isIndividual
      ? yup.mixed().nullable().optional()
      : yup.object().shape({
          first_name: yup.string().required('First name is a required field'),
          last_name: yup.string().required('Last name is a required field'),
          title: yup.string().required('Job title is a required field'),
          id_number: yup.string().when('show_full_ssn', ([show_full_ssn], schema) => {
            return show_full_ssn
              ? schema.required('SSN is a required field')
              : schema.notRequired().nullable();
          }),
          dob: yup
            .mixed()
            .required('Date of birth is a required field')
            .test(
              'dob',
              'Company representatives must be at least 13 years old to create a business account.',
              function (value, ctx) {
                const dob = new Date(value as string);
                const today = new Date();
                const isOlderThan13 = differenceInYears(today, dob) >= 13;

                return !isOlderThan13 ? ctx.createError() : isOlderThan13;
              },
            ),
          ssn_last_4: yup.string().when('show_full_ssn', ([show_full_ssn], schema) => {
            return show_full_ssn
              ? schema.notRequired().nullable()
              : schema.required('Last 4 digits of SSN is a required field');
          }),
          phone: yup.string().phone().required('Phone number is a required field'),
          email: yup.string().email('Must be a valid email').required('Email is a required field'),
          use_business_address: yup.boolean(),
          address_first: yup
            .string()
            .when('use_business_address', ([use_business_address], schema) => {
              return useBusinessAddressIsNotChecked(use_business_address, schema, 'Street Address');
            }),
          address_city: yup
            .string()
            .when('use_business_address', ([use_business_address], schema) => {
              return useBusinessAddressIsNotChecked(use_business_address, schema, 'City');
            }),
          address_zip: yup
            .string()
            .when('use_business_address', ([use_business_address], schema) => {
              return useBusinessAddressIsNotChecked(use_business_address, schema, 'Zipcode');
            }),
        }),
  });
};

export type TaxInfo = yup.InferType<ReturnType<typeof TaxInfoTabSchema>>;
